/* set default background color to #ddd */
body {
   background-color: #ddd;
}

.floating-div {
   position: fixed;
   top: 12px;
   left: 12px;
   padding: 12px;
   z-index: 1000;
}
.paidRow {
   background-color: rgba(0, 128, 0, 0.2); /* Translucent green */
}
.lateRow {
   background-color: rgba(255, 0, 0, 0.2); /* Translucent red */
}
/* div to float in bottom right corner of the screen */
.bottom-right {
   position: fixed;
   bottom: 8px;
   right: 16px;
   z-index: 1000;
   color: #ddd;
   size: 15px !important;
}
/* Add this to your CSS file */
@keyframes fadeInRed {
   from {
      background-color: rgba(255, 0, 0, 0);
   }
   to {
      background-color: rgba(255, 0, 0, 0.2);
   }
}

.inactiveRow {
   animation: fadeInRed 1.1s forwards;
}
.dataGridContainer {
   height: 700px; /* Adjust the height as needed */
   width: 100%;
}
